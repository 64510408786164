import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/bottom.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faComments,
  faGear,
  faPhone,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";

function Privacy() {
  return (
    <div className="main-container">
      <Header />
      <div className="contactAll">
        <div className="contactLeftBottomTitle">
          <div className="term-h1">
            <h1>Privacy policy</h1>
          </div>
          <p>
            This section summarises how we obtain, store and use information
            about you. It is intended to provide a very general overview only.
            It is not complete in and of itself and it must be read in
            conjunction with the corresponding full sections of this Privacy
            Policy.
          </p>
          <p>
            Data controller: E-cloth Ltd
            <br />
            How we collect or obtain information about you:
            <br />
            when you provide it to us (e.g. by contacting us, placing an order
            on our website or by signing up to our e-newsletter),
            <br />
            from your use of our website, using cookies, and
            <br />
            occasionally, from third parties.
            <br />
            Information we collect: name, contact details, IP address,
            information from cookies and tracking codes on our website,
            information about your computer or device (e.g. device and browser
            type), information about how you use our website (e.g. which pages
            you have viewed, the time when you view them and what you clicked
            on, the geographical location from which you accessed our website
            (based on your IP address) and the company name or business name (if
            applicable).
            <br />
            How we use your information: for administrative and business
            purposes (particularly to contact you and process orders you place
            on our website), to improve our business and website, to fulfil our
            contractual obligations, to advertise our goods and services, to
            analyse your use of our website, and in connection with our legal
            rights and obligations.
            <br />
            Disclosure of your information to third parties: only to the extent
            necessary to run our business, to our service providers, to fulfil
            any contracts we enter into with you, and where required by law or
            to enforce our legal rights.
            <br />
            Do we sell your information to third parties (other than in the
            course of a business sale or purchase or similar event): No
            <br />
            How long we retain your information: for no longer than necessary,
            taking into account any legal obligations we have (e.g. to maintain
            records for tax purposes), any other legal basis we have for using
            your information (e.g. your consent, performance of a contract with
            you or our legitimate interests as a business) and certain
            additional factors described in the main section below entitled How
            long we retain your information. For specific retention periods in
            relation to certain information which we collect from you, please
            see the main section below entitled How long we retain your
            information.
            <br />
            How we secure your information: using appropriate technical and
            organisational measures such as storing your information on secure
            servers, encrypting transfers of data to or from our servers using
            Secure Sockets Layer (SSL) technology, only granting access to your
            information where necessary.
            <br />
            Use of automated decision-making and profiling: we use automated
            decision-making and profiling on our website through our use of web
            analytics, ‘heat mapping’ and remarketing cookies. For further
            information, please see Use of automated decision making and
            profiling
            <br />
            Use of cookies: we use cookies on our website including essential,
            functional, analytical and advertising cookies. For more
            information, please visit our cookies policy here:
            https://www.e-cloth.com/pages/cookie-policy
            <br />
            Transfers of your information outside the European Economic Area: In
            certain circumstances, we will transfer your information outside of
            the European Economic Area, including to the United States of
            America. Where we do so, we will ensure appropriate safeguards are
            in place, including, for example, that the third parties we use who
            transfer your information outside the European Economic Area have
            self-certified themselves as compliant with the EU-U.S. Privacy
            Shield.
            <br />
            Your rights in relation to your information
            <br />
            to access your information and to receive information about its use
            <br />
            to have your information corrected and/or completed
            <br />
            to have your information deleted
            <br />
            to restrict the use of your information
            <br />
            to receive your information in a portable format
            <br />
            to object to the use of your information
            <br />
            to withdraw your consent to the use of your information
            <br />
            to complain to a supervisory authority
            <br />
            Sensitive personal information: we do not knowingly or intentionally
            collect what is commonly referred to as ‘sensitive personal
            information’. Please do not submit sensitive personal information
            about you to us. For more information, please see the main section
            below entitled Sensitive Personal Information.
            <br />
            Our details
          </p>
          <p>
            The data controller in respect of our website is E-cloth Ltd, a
            limited company registered in England and Wales (company
            registration number 03111707). Our address is E-cloth Ltd, East
            Barn, Furnace Farm, Furnace Lane, Lamberhurst, Kent TN3 8LE.
          </p>
          <p>
            You can contact the data controller by writing to E-cloth Ltd, East
            Barn, Furnace Farm, Furnace Lane, Lamberhurst, Kent TN3 8LE or
            sending an email to connect@uke-cloth.xyz.
          </p>

          <p>
            If you have any questions about this Privacy Policy, please contact
            the data controller.
          </p>
          <p>Information we collect when you visit our website</p>
          <p>
            We collect and use information from website visitors in accordance
            with this section and the section entitled Disclosure and additional
            uses of your information.
          </p>
          <p>Web server log information</p>
          <p>
            We use a third party server to host our website. Our website server
            automatically logs the IP address you use to access our website as
            well as other information about your visit such as the pages
            accessed, information requested, the date and time of the request,
            the source of your access to our website (e.g. the website or URL
            (link) which referred you to our website), and your browser version
            and operating system.
          </p>
          <p>Our server is located in the United Kingdom.</p>
          <p>Use of website server log information for IT security purposes</p>
          <p>
            Our third party hosting provider collects and stores server logs to
            ensure network and IT security and so that the server and website
            remain uncompromised. This includes analysing log files to help
            identify and prevent unauthorised access to our network, the
            distribution of malicious code, denial of services attacks and other
            cyber attacks, by detecting unusual or suspicious activity.
          </p>
          <p>
            Unless we are investigating suspicious or potential criminal
            activity, we do not make, nor do we allow our hosting provider to
            make, any attempt to identify you from the information collected via
            server logs.
          </p>
          <p>
            Legal basis for processing:compliance with a legal obligation to
            which we are subject (Article 6(1)(c) of the General Data Protection
            Regulation).
            <br />
            Legal obligation: we have a legal obligation to implement
            appropriate technical and organisational measures to ensure a level
            of security appropriate to the risk of our processing of information
            about individuals. Recording access to our website using server log
            files is such a measure.
          </p>
          <p>
            Legal basis for processing:our legitimate interests (Article 6(1)(f)
            of the General Data Protection Regulation).
            <br />
            Legitimate interests: we have a legitimate interest in using your
            information for the purposes of ensuring network and information
            security.
          </p>
          <p>Cookies and similar technologies</p>
          <p>
            Cookies are data files which are sent from a website to a browser to
            record information about users for various purposes.
          </p>
          <p>
            We use cookies on our website, including essential, functional,
            analytical and marketing cookies. For further information on how we
            use cookies, please see our cookies policy which is available here:
            https://www.e-cloth.com/pages/cookie-policy
          </p>
          <p>
            You can reject some or all of the cookies we use on or via our
            website by changing your browser settings but doing so can impair
            your ability to use our website or some or all of its features. For
            further information about cookies, including how to change your
            browser settings, please visit www.allaboutcookies.orgor see our
            cookies policy.
          </p>
          <p>Information we collect when you contact us</p>
          <p>
            We collect and use information from individuals who contact us in
            accordance with this section and the section entitled Disclosure and
            additional uses of your information.
          </p>
          <p>Email</p>
          <p>
            When you send an email to the email address displayed on our website
            we collect your email address and any other information you provide
            in that email (such as your name, telephone number and the
            information contained in any signature block in your email).
          </p>
          <p>
            Legal basis for processing:ourlegitimate interests (Article 6(1)(f)
            of the General Data Protection Regulation).
            <br />
            Legitimate interest(s): responding to enquiries and messages we
            receive and keeping records of correspondence.
          </p>
          <p>
            Legal basis for processing: necessary to perform a contract or to
            take steps at your request to enter into a contract (Article 6(1)(b)
            of the General Data Protection Regulation).
            <br />
            Reason why necessary to perform a contract: where your message
            relates to us providing you with goods or services or taking steps
            at your request prior to providing you with our goods and services
            (for example, providing you with information about such goods and
            services), we will process your information in order to do so)
          </p>
          <p>Transfer and storage of your information</p>
          <p>
            Emails you send us will be stored within the European Economic Area
            on our servers in the United Kingdom.For further information please
            see the section of this privacy policy entitled Transfers of your
            information outside the European Economic Area.
          </p>
          <p>Contact form</p>
          <p>
            When you contact us using our contact form, we collect the following
            information: your name, email address and any information you
            include in the message/enquiry field. We also collect any other
            information you provide to us when you complete the contact form,
            including any optional information, such as: your telephone number
            and postal address.
          </p>
          <p>
            If you do not provide the mandatory information required by our
            contact form, you will not be able to submit the contact form and we
            will not receive your enquiry.
          </p>
          <p>
            If you do not supply the optional information required by our
            contact form, such as phone number or postal address, then we will
            not be able to respond to your enquiry by phone or post.
          </p>
          <p>
            Legal basis for processing:ourlegitimate interests (Article 6(1)(f)
            of the General Data Protection Regulation).
            <br />
            Legitimate interest(s): responding to enquiries and messages we
            receive and keeping records of correspondence.
          </p>
          <p>
            Legal basis for processing: necessary to perform a contract or to
            take steps at your request to enter into a contract (Article 6(1)(b)
            of the General Data Protection Regulation).
            <br />
            Reason why necessary to perform a contract: where your message
            relates to us providing you with goods or services or taking steps
            at your request prior to providing you with our goods and services
            (for example, providing you with information about such goods and
            services), we will process your information in order to do so).
          </p>
          <p>&nbsp;</p>
          <p>Transfer and storage of your information</p>
          <p>
            We use a third party service to store information collected via our
            contact form, called MailChimp. You can access their privacy policy
            here: https://mailchimp.com/legal/privacy/
          </p>
          <p>
            Information you submit to our contact form will be stored outside
            the European Economic Area on MailChimp’s servers in the United
            States of America. For further information about the safeguards used
            when your information is transferred outside the European Economic
            Area, see the section of this privacy policy below entitled
            Transfers of your information outside the European Economic Area.
          </p>
          <p>Phone</p>
          <p>
            When you contact us by phone, we collect your phone number and any
            information provide to us during your conversation with us.
          </p>
          <p>
            Legal basis for processing:ourlegitimate interests (Article 6(1)(f)
            of the General Data Protection Regulation).
            <br />
            Legitimate interest(s): responding to enquiries and messages we
            receive and keeping records of correspondence.
          </p>
          <p>
            Legal basis for processing: necessary to perform a contract or to
            take steps at your request to enter into a contract (Article 6(1)(b)
            of the General Data Protection Regulation).
            <br />
            Reason why necessary to perform a contract: where your message
            relates to us providing you with goods or services or taking steps
            at your request prior to providing you with our goods and services
            (for example, providing you with information about such goods and
            services), we will process your information in order to do so).
          </p>
          <p>Transfer and storage of your information</p>
          <p>
            Information about your call, such as your phone number and the date
            and time of your call, is processed by our third party telephone
            service provider and stored in the United Kingdom.
          </p>
          <p>Post</p>
          <p>
            If you contact us by post, we will collect any information you
            provide to us in any postal communications you send us.
          </p>
          <p>
            Legal basis for processing:ourlegitimate interests (Article 6(1)(f)
            of the General Data Protection Regulation)
            <br />
            Legitimate interest(s): responding to enquiries and messages we
            receive and keeping records of correspondence.
          </p>
          <p>Transfer and storage of your information</p>
          <p>
            Information you submit to us by post will be stored in the United
            Kingdom.
          </p>
          <p>Information we collect when you interact with our website</p>
          <p>
            We collect and use information from individuals who interact with
            particular features of our website in accordance with this section
            and the section entitled Disclosure and additional uses of your
            information.
          </p>
          <p>E-Newsletter</p>
          <p>
            When you sign up for our e-newsletter on our website by clicking on
            the subscribe button on our website’s homepage, we collect your name
            and email address.
          </p>
          <p>
            Legal basis for processing:yourconsent (Article 6(1)(a) of the
            General Data Protection Regulation).
            <br />
            Consent: you give your consent to us sending you our e-newsletter by
            signing up to receive it using the steps described above.
          </p>
          <p>Transfer and storage of your information</p>
          <p>
            We use a third party service to send out our e-newsletter and
            administer our mailing list, called MailChimp. You can access their
            privacy policy https://mailchimp.com/legal/privacy/
          </p>
          <p>
            Information you submit to subscribe for our e-newsletter will be
            stored outside the European Economic Area on MailChimp’s servers in
            the United States of America. For further information about the
            safeguards used when your information is transferred outside the
            European Economic Area, see the section of this privacy policy below
            entitled Transfers of your information outside the European Economic
            Area.
          </p>
          <p>Information we collect when you place an order on our website</p>
          <p>
            We collect and use information from individuals who place an order
            on our website in accordance with this section and the section
            entitled Disclosure and additional uses of your information.
          </p>
          <p>Information collected when you place an order</p>
          <p>Mandatory information</p>
          <p>
            When you place an order for goods or services on our website, we
            collect your name, email address, billing address and shipping
            address, telephone number and mobile number.
          </p>
          <p>
            If you do not provide this information, you will not be able to
            purchase goods or services from us on our website or enter into a
            contract with us.
          </p>
          <p>
            Legal basis for processing:necessary to perform a contract (Article
            6(1)(b) of the General Data Protection Regulation).
            <br />
            Reason why necessary to perform a contract: we need the mandatory
            information collected by our checkout form to establish who the
            contract is with and to contact you to fulfil our obligations under
            the contract, including sending you receipts and order
            confirmations.
          </p>
          <p>
            Legal basis for processing: compliance with a legal obligation
            (Article 6(1)(c) of the General Data Protection Regulation).
            <br />
            Legal obligation: we have a legal obligation to issue you with an
            invoice for the goods and services you purchase from us where you
            are VAT registered and we require the mandatory information
            collected by our checkout form for this purpose. We also have a
            legal obligation to keep accounting records, including records of
            transactions
          </p>
          <p>
            Legal basis for processing: we collect your phone number for our
            legitimate interests (Article 6(1)(f) of the General Data Protection
            Regulation).
            <br />
            Legitimate interest(s):we collect your phone number for our
            legitimate interest of more easily and effectively contacting you in
            relation to your order and your account.
          </p>
          <p>Transfer and storage of your information</p>
          <p>
            Information you submit to us when you place an order will be stored
            in the United Kingdom.
          </p>
          <p>Processing your payment</p>
          <p>
            After you place an order on our website you will need to make
            payment for the goods or services you have ordered. In order to
            process your payment, we use a third party payment processor
            calledSECPay (Pay360). Your payment will be processed by SECPay
            (Pay360).
          </p>
          <p>
            The third party payment processor you choose to process your payment
            collects, uses and processes your information, including payment
            information, in accordance with their privacy policies. You can
            access their privacy policy via the following link:
            https://www.pay360.com/about-us/privacy-policy
          </p>
          <p>Transfer and storage of your information</p>
          <p>
            Information relating to the processing of your payment is stored
            within the European Economic Area by SECPay in the United Kingdom.
          </p>
          <p>
            Legal basis for processing:necessary to perform a contract (Article
            6(1)(b) of the General Data Protection Regulation).
            <br />
            Reason why necessary to perform a contract: to fulfil your
            contractual obligation to pay for the goods or services you have
            ordered from us.
          </p>
          <p>Marketing communications</p>
          <p>
            On our ‘request a directory’ form, you will have the option of
            receiving marketing communications from us.
          </p>
          <p>Offers relating to our goods and services</p>
          <p>
            You can opt-in to receiving marketing communications in relation to
            our goods and services, by selecting the subscribe button on our
            websites homepage, indicating that you would like to receive offers
            about our goods and services.
          </p>
          <p>
            We will send you marketing communications in relation to our own
            goods and services, and our partners products that we may sell on
            our website.
          </p>
          <p>
            Legal basis for processing: consent (Article 6(1)(a) of the General
            Data Protection Regulation).
            <br />
            Consent: you give your consent to us sending you information about
            our goods and services by signing up to receive such information in
            accordance with the steps described above.
          </p>
          <p>Transfer and storage of your information</p>
          <p>
            We use a third party mailing list providerto send out our
            e-newsletter and administer our mailing list, called MailChimp. You
            can access their privacy policy here:
            https://mailchimp.com/legal/privacy/
          </p>
          <p>
            Information you submit to subscribe for our newsletter will be
            stored outside the European Economic Area on MailChimp’s servers in
            the United States of America. For further information about the
            safeguards used when your information is transferred outside the
            European Economic Area, see the section of this privacy policy below
            entitled Transfers of your information outside the European Economic
            Area.
          </p>
          <p>Information collected or obtained from third parties</p>
          <p>
            This section sets out how we obtain or collect information about you
            from third parties.
          </p>
          <p>Information received from third parties</p>
          <p>
            Generally, we do not receive information about you from third
            parties. The third parties from which we receive information about
            you will generally include other businesses and clients we work with
            from time to time who may recommend our services to you. These could
            be businesses in any industry, sector, sub-sector or location.
          </p>
          <p>
            It is also possible that third parties with whom we have had no
            prior contact may provide us with information about you.
          </p>
          <p>
            Information we obtain from third parties will generally be your name
            and contact details, but will include any additional information
            about you which they provide to us.
          </p>
          <p>
            Legal basis for processing:necessary to perform a contract or to
            take steps at your request to enter into a contract (Article 6(1)(b)
            of the General Data Protection Regulation).
            <br />
            Reason why necessary to perform a contract: where a third party has
            passed on information about you to us (such as your name and email
            address) in order for us to provide services to you, we will process
            your information in order to take steps at your request to enter
            into a contract with you and perform a contract with you (as the
            case may be).
          </p>
          <p>
            Legal basis for processing:consent (Article 6(1)(a) of the General
            Data Protection Regulation).<span>&nbsp;</span>
            <br />
            Consent: where you have asked that a third party to share
            information about you with us and the purpose of sharing that
            information is not related to the performance of a contract or
            services by us to you, we will process your information on the basis
            of your consent, which you give by asking the third party in
            question to pass on your information to us.
          </p>
          <p>
            Legal basis for processing:our legitimate interests (Article 6(1)(f)
            of the General Data Protection Regulation).<span>&nbsp;</span>
            <br />
            Legitimate interests: where a third party has shared information
            about you with us and you have not consented to the sharing of that
            information, we will have a legitimate interest in processing that
            information in certain circumstances.
          </p>
          <p>
            For example, we would have a legitimate interest in processing your
            information to perform our obligations under a sub-contract with the
            third party, where the third party has the main contract with you.
            Our legitimate interest is the performance of our obligations under
            our sub-contract.
          </p>
          <p>
            Similarly, third parties may pass on information about you to us if
            you have infringed or potentially infringed any of our legal rights.
            In this case, we will have a legitimate interest in processing that
            information to investigate and pursue any such potential
            infringement.
          </p>
          <p>Where we receive information about you in error</p>
          <p>
            If we receive information about you from a third party in error
            and/or we do not have a legal basis for processing that information,
            we will delete your information.
          </p>
          <p>Information obtained by us from third parties</p>
          <p>
            In certain circumstances (for example, to verify the information we
            hold about you or obtain missing information we require to provide
            you with a service) we will obtain information about you from
            certain publicly accessible sources, both EU and non-EU, such as
            Companies House, online customer databases, business directories,
            media publications, social media, and websites (including your own
            website if you have one) and any affiliates.
          </p>
          <p>
            Legal basis for processing: necessary to perform a contract or to
            take steps at your request to enter into a contract (Article 6(1)(b)
            of the General Data Protection Regulation).
            <br />
            Reason why necessary to perform a contract: where you have entered
            into a contract or requested that we enter into a contract with you,
            in certain circumstances, we will obtain information about you from
            public sources in order to enable us to understand your business and
            provide services to you or services to a sufficient standard.
          </p>
          <p>
            For example, we would obtain and/or verify your email address from
            your website or from a directory where you ask us to send you
            information by email but we do not possess the information or we
            need to confirm that we have recorded your email address correctly.
          </p>
          <p>
            Legal basis for processing: our legitimate interests (Article
            6(1)(f) of the General Data Protection Regulation).
            <br />
            Legitimate interests: in certain circumstances, we will have a
            legitimate interest in obtaining information about you from public
            and private sources. For example, if you have infringed or we
            suspect that you have infringed any of our legal rights, we will
            have a legitimate interest in obtaining and processing information
            about you from such sources in order to investigate and pursue any
            suspected or potential infringement.
          </p>

          <p>Our use of automated decision-making and profiling</p>
          <p>
            We use automated decision making and profiling on our website. We do
            not consider that this has any legal effect on you or similarly
            significantly affects you.
          </p>
          <p>
            You have the right to object to our use of automated decision making
            and profiling described in this section. You can do that by
            opting-out of cookies and similar technologies in accordance with
            the method described in the relevant section below. If you do not
            want us to process your actual IP address (usually the IP address
            assigned to you by your Internet Service Provider) when you visit
            our website, you can use a Virtual Private Network (VPN) or a free
            service such as Tor.
          </p>
          <p>
            You can find out more about our use of cookies and similar
            technologies (including the legal basis on which we use them) and
            how to opt out from them in our cookies policy, which is available
            here: https://www.e-cloth.com/pages/cookie-policy
          </p>
          <p>Automated decision making</p>
          <p>
            Automated decision making is decision making by technological means
            (i.e. by a machine) without human involvement.
          </p>
          <p>Use of automated decision making for advertising</p>
          <p>
            We use several advertising services, including Rubicon project,
            ScorecardResearch and Google remarketing which involve automated
            decision-making. These services collect information to automate the
            display advertisements containing our products and services on other
            websites you visit, you based on the fact that you have visited our
            website using cookies. For further information on the cookies we use
            involving Rubicon Project, ScorecardResearch and Google remarketing,
            please see our cookies policy which is available here:
            https://www.e-cloth.com/pages/cookie-policy
          </p>
          <p>
            Logic involved: automatically displaying advertisements to
            individuals who have visited our website results in increased
            efficiencies and costs savings for us than manually displaying
            advertisements or displaying advertisements by different means.
          </p>
          <p>
            Significance and envisaged consequences: cookies will be used to
            recognise the fact that you have visited our website in order to
            display advertisements to you (unless you have blocked such cookies)
            and will collect information about your online behaviour.
          </p>
          <p>
            How to object: you can block these cookies by following the
            instructions on the following links to opt-out or using our cookie
            control tool (if you have turned on these cookies):
          </p>
          <p>
            ScorecardResearch:
            https://www.scorecardresearch.com/Preferences.aspx?newlanguage=1
            <span>&nbsp;</span>
            <br />
            Rubicon project:
            https://rubiconproject.com/privacy/consumer-online-profile-and-opt-out/
            <span>&nbsp;</span>
            <br />
            Google remarketing:
            https://support.google.com/ads/answer/2662922?hl=en-GB
            <span>&nbsp;</span>
            <br />
            For further information, please see our cookies policy:
            https://www.e-cloth.com/pages/cookie-policy
          </p>
          <p>Profiling</p>
          <p>
            Profiling is any form of automated processing of your information to
            evaluate personal aspects about you, in particular to analyse or
            predict things like your performance at work, economic situation,
            health, personal preferences, interests, reliability, behaviour,
            location or movements.
          </p>
          <p>Use of profiling for web analytics</p>
          <p>
            Our web analytics service, Google Analytics uses collects
            information such as your location (based on your IP address) and
            your behaviour (based on cookies) when you access our website (such
            as the pages you visit and what you click on). We will only process
            information from cookies if you have consented to us setting cookies
            on your computer in accordance with our cookies policy
            (https://www.e-cloth.com/pages/cookie-policy). Please see our
            cookies policy for more information about our use of Google
            Analytics.
          </p>
          <p>
            Logic involved: by automatically analysing and categorising
            information such as the location (based on IP address) as well as
            the behaviour and devices of visitors to our website (using
            cookies), we are able to gain a better understanding of what our
            website visitors want (in terms of the content of our website and
            our products), how to improve our website and how to advertise and
            market our services to them.
          </p>
          <p>
            Significance and envisaged consequences: cookies will be used to
            track and store information about your behaviour and device on our
            website where you have allowed cookies using our cookie tool and
            your location will be analysed based on your IP address. More
            information found on our cookies policy here:
            https://www.e-cloth.com/pages/cookie-policy.
          </p>
          <p>
            Legal basis for processing:ourlegitimate interests (Article 6(1)(f)
            of the General Data Protection Regulation).<span>&nbsp;</span>
            <br />
            Legitimate interest: improving our website for our website users and
            getting to know our website user’s preferences so our website can
            better meet their needs and desires.
          </p>
          <p>Use of profiling through Heat mapping</p>
          <p>
            Our web heat mapping service, Crazy Egg, uses heatmaps to give an
            overview of what people do when they use our website. Using colours
            on a scale from cold to hot, Crazy Egg heatmaps show the ‘hottest’
            elements that get the most interaction and views. We use cookies to
            provide this functionality and we will only process information from
            cookies if you have consented to us setting cookies on your computer
            in accordance with our cookies policy
            (https://www.e-cloth.com/pages/cookie-policy). Please see our
            cookies policy for more information about our use of Crazy Egg.
          </p>
          <p>
            Logic involved: by automatically analysing and categorising
            information such as your mouse clicks, keystrokes, pages visited and
            the duration of your visit to our website, we are able to gain a
            better understanding of what our website visitors want (in terms of
            the content of our website and our products), how to improve our
            website and how to advertise and market our services to them.
          </p>
          <p>
            Significance and envisaged consequences: cookies will be used to
            track and store information about your behaviour and device on our
            website where you have allowed cookies using our cookie tool. More
            information found on our cookies policy here:
            https://www.e-cloth.com/pages/cookie-policy.
          </p>
          <p>
            Legal basis for processing:ourlegitimate interests (Article 6(1)(f)
            of the General Data Protection Regulation).<span>&nbsp;</span>
            <br />
            Legitimate interest: improving our website for our website users and
            getting to know our website user’s preferences so our website can
            better meet their needs and desires.
          </p>
          <p>
            Use of profiling through Facebook advertising and Facebook Pixel
          </p>
          <p>
            We use profiling to advertise on Facebook. Information collected by
            Facebook in relation to its advertisements and the Facebook Pixel on
            our website is transferred by Facebook outside the European Economic
            Area. For further information and information on the safeguards
            used, please see the section of this privacy policy entitled
            Transfers of your information outside the European Economic Area.
          </p>
          <p>
            Logic involved: by targeting individuals based on criteria such as
            what they have liked on Facebook, we are able to more effectively
            advertise our services to those who we think are most likely to be
            interested in purchasing them, making our advertising more
            cost-effective and relevant.
          </p>
          <p>
            Significance and envisaged consequences: advertisements will be
            displayed to you on Facebook if you meet the relevant criteria which
            form the basis of our advertising campaign. If you click on any
            relevant advertisements displayed to you on Facebook, your behaviour
            will be tracked using the code (Facebook Pixel) which we have
            installed on our website (if you have consented to the use of such
            technologies on our website) and your actions on our site will be
            tracked and the information shared with Facebook (you can access
            Facebook’s Privacy Policy here:
            https://www.facebook.com/about/privacy/). Cookies and tracking code
            will be used to track and store information about your behaviour and
            device on our website if you have turned them on using our cookie
            tool. Further information about our use of Facebook Pixel can be
            found here: https://www.e-cloth.com/pages/cookie-policy.
          </p>
          <p>
            How to object: If you accepted the use of Facebook Pixel using our
            cookie tool, you can use our cookie tool to turn it off and stop it
            processing your information as well. You can also opt-out of
            Facebook processing your information using Facebook Pixel by
            changing your ad settings in your Facebook account here:
            https://www.facebook.com/ads/settings<span>&nbsp;</span>
            <br />
            You can opt out of Facebook and other companies that participate in
            the Digital Advertising Alliance in Europe from showing you interest
            based ads by visiting http://www.youronlinechoices.com, selecting
            your country, clicking ‘Your Ad Choices’, then locating Facebook
            (and any other companies you want to block) and selecting the ‘Off’
            option.
          </p>
          <p>
            Legal basis for processing: our legitimate interests (Article
            6(1)(f) of the General Data Protection Regulation).
            <span>&nbsp;</span>
            <br />
            Legitimate interest: marketing our goods and services effectively.
          </p>
          <p>Disclosure and additional uses of your information</p>
          <p>
            This section sets out the circumstances in which will disclose
            information about you to third parties and any additional purposes
            for which we use your information.
          </p>
          <p>Disclosure of your information to service providers</p>
          <p>
            We use a number of third parties to provide us with services which
            are necessary to run our business or to assist us with running our
            business and who process your information for us on our behalf.
            These include the following:
          </p>
          <p>
            Telephone provider (UK),<span>&nbsp;</span>
            <br />
            Email provider (UK),<span>&nbsp;</span>
            <br />
            Mailing list provider (USA),<span>&nbsp;</span>
            <br />
            IT service provider (UK),<span>&nbsp;</span>
            <br />
            Web developer (UK), and<span>&nbsp;</span>
            <br />
            Hosting provider (UK).<span>&nbsp;</span>
            <br />
            Apart from our mailing list provider, which is located in the United
            States of America, our service providers are located in the United
            Kingdom.
          </p>
          <p>
            Your information will be shared with these service providers where
            necessary to provide you with the service you have requested,
            whether that is accessing our website or ordering goods and services
            from us.
          </p>
          <p>
            We do not display the identities of all of our service providers
            publicly by name for security and competitive reasons. If you would
            like further information about the identities of our service
            providers, however, please contact us directly by email and we will
            provide you with such information where you have a legitimate reason
            for requesting it (where we have shared your information with such
            service providers, for example).
          </p>
          <p>
            Legal basis for processing:legitimate interests (Article 6(1)(f) of
            the General Data Protection Regulation).<span>&nbsp;</span>
            <br />
            Legitimate interest relied on: where we share your information with
            these third parties in a context other than where is necessary to
            perform a contract (or take steps at your request to do so), we will
            share your information with such third parties in order to allow us
            to run and manage our business efficiently.
          </p>
          <p>
            Legal basis for processing:necessary to perform a contract and/or to
            take steps at your request prior to entering into a contract
            (Article 6(1)(b) of the General Data Protection Regulation).
            <span>&nbsp;</span>
            <br />
            Reason why necessary to perform a contract: we may need to share
            information with our service providers to enable us to perform our
            obligations under that contract or to take the steps you have
            requested before we enter into a contract with you.
          </p>
          <p>Disclosure of your information to other third parties</p>
          <p>
            We disclose your information to other third parties in specific
            circumstances, as set out below.
          </p>
          <p>
            Providing information to Google, Inc., Crazy Egg, Inc, Rubicon
            Project, ScorecardResearch and Facebook
          </p>
          <p>
            Google and Crazy Egg collect information through our use of Google
            Analytics and Crazy Egg (respectively on our website) on our
            website. Google uses this information, including IP addresses and
            information from cookies, for a number of purposes, such as
            improving its Google Analytics service. Information is shared with
            Google on an aggregated and anonymised basis. To find out more about
            what information Google collects, how it uses this information and
            how to control the information sent to Google, please see the
            following page: https://www.google.com/policies/privacy/partners/
          </p>
          <p>
            Crazy Egg collects information through code and cookies such as your
            mouse behaviour our website and uses it to analyse and improve its
            Crazy Egg service. To find out more about what information Crazy Egg
            collects, how it uses this information and how to control the
            information sent to Crazy Egg, please see the following page:
            https://www.crazyegg.com/privacy
          </p>
          <p>
            Rubicon Project and ScorecardResearch both collect information about
            your behaviour on our website using cookies and use those cookies to
            display advertisements to you. For further information, please see
            their privacy policies which are available via the following links:
            https://rubiconproject.com/privacy-policy/and
            https://www.comscore.com/About-comScore/Privacy-Policy
          </p>
          <p>
            We share information with Facebook to enable it to provide us with
            the information we need to run our advertisements effectively on
            Facebook and to run Facebook advertising campaigns. Facebook
            collects information about your interaction with our website using
            its Facebook Pixel and related cookies. You can find out more about
            what Facebook collects from third party applications and websites in
            its privacy policy which is available here:
            https://www.facebook.com/full_data_use_policy
          </p>
          <p>
            Legal basis for processing:ourlegitimate interests (Article 6(1)(f)
            of the General Data Protection Regulation).<span>&nbsp;</span>
            <br />
            Legitimate interest(s): meeting our contractual obligations to these
            third parties under our contracts with them
            (https://www.google.com/analytics/terms/us.html).
          </p>
          <p>Transfer and storage of your information</p>
          <p>
            Information collected by these third parties is transferred and
            stored outside the European Economic Area (EEA).
          </p>
          <p>
            For further information about the safeguards used when your
            information is transferred outside the European Economic Area, see
            the section of this privacy policy below entitled Transfers of your
            information outside the European Economic Area.
          </p>
          <p>&nbsp;</p>
          <p>
            Sharing your information with third parties, which are either
            related to or associated with the running of our business, where it
            is necessary for us to do so. These third parties include our
            accountants, advisors, business partners, independent contractors,
            and insurers.
          </p>
          <p>
            Further information on each of these third parties is set out below.
          </p>
          <p>
            Legal basis for processing:ourlegitimate interests (Article 6(1)(f)
            of the General Data Protection Regulation).<span>&nbsp;</span>
            <br />
            Legitimate interest: running and managing our business efficiently.
          </p>
          <p>Accountants</p>
          <p>
            We share information with our accountants for tax purposes. For
            example, we share invoices we issue and receive with our accountants
            for the purpose of completing tax returns and our end of year
            accounts.
          </p>
          <p>Our accountants are located in the United Kingdom.</p>
          <p>Advisors</p>
          <p>
            Occasionally, we obtain advice from advisors, such as accountants,
            financial advisors, lawyer and public relations professionals. We
            will share your information with these third parties only where it
            is necessary to enable these third parties to be able to provide us
            with the relevant advice.
          </p>
          <p>Our advisors are located in the United Kingdom.</p>
          <p>Business partners</p>
          <p>
            Business partners are businesses we work with which provide goods
            and services which are complementary to our own or which allow us to
            provide goods or services which we could not provide on our own. We
            share information with our business partners where you have
            requested services which they provide whether independently from, or
            in connection with or own services.
          </p>
          <p>Our business partners are located in the United Kingdom.</p>
          <p>Independent contractors</p>
          <p>
            Occasionally, we use independent contractors in our business. Your
            information will be shared with independent contractors only where
            it is necessary for them to perform the function we have hired them
            perform in relation to our business.
          </p>
          <p>Our independent contractors are located in the United Kingdom.</p>
          <p>Insurers</p>
          <p>
            We will share your information with our insurers where it is
            necessary to do so, for example in relation to a claim or potential
            claim we receive or make or under our general disclosure obligations
            under our insurance contract with them.
          </p>
          <p>Our insurers are located in the United Kingdom.</p>
          <p>
            Sharing your information with a prospective or actual purchaser or
            seller in the context of a business or asset sale or acquisition by
            us, a merger or similar business combination event, whether actual
            or potential.
          </p>
          <p>
            Legal basis for processing:legitimate interests (Article 6(1)(f) of
            the General Data Protection Regulation).<span>&nbsp;</span>
            <br />
            Legitimate interest(s):sharing your information with a prospective
            purchaser, seller or similar person in order to allow such a
            transaction to take place.
          </p>
          <p>Disclosure and use of your information for legal reasons</p>
          <p>
            Indicating possible criminal acts or threats to public security to a
            competent authority
          </p>
          <p>
            If we suspect that criminal or potential criminal conduct has been
            occurred, we will in certain circumstances need to contact an
            appropriate authority, such as the police. This could be the case,
            for instance, if we suspect that a fraud or a cyber crime has been
            committed or if we receive threats or malicious communications
            towards us or third parties.
          </p>
          <p>
            We will generally only need to process your information for this
            purpose if you were involved or affected by such an incident in some
            way.
          </p>
          <p>
            Legal basis for processing:ourlegitimate interests (Article 6(1)(f)
            of the General Data Protection Regulation).<span>&nbsp;</span>
            <br />
            Legitimate interests: preventing crime or suspected criminal
            activity (such as fraud).
          </p>
          <p>
            In connection with the enforcement or potential enforcement our
            legal rights
          </p>
          <p>
            We will use your information in connection with the enforcement or
            potential enforcement of our legal rights, including, for example,
            sharing information with debt collection agencies if you do not pay
            amounts owed to us when you are contractually obliged to do so. Our
            legal rights may be contractual (where we have entered into a
            contract with you) or non-contractual (such as legal rights that we
            have under copyright law or tort law).
          </p>
          <p>
            Legal basis for processing: our legitimate interests (Article
            6(1)(f) of the General Data Protection Regulation).
            <span>&nbsp;</span>
            <br />
            Legitimate interest:enforcing our legal rights and taking steps to
            enforce our legal rights.
          </p>
          <p>
            In connection with a legal or potential legal dispute or proceedings
          </p>
          <p>
            We may need to use your information if we are involved in a dispute
            with you or a third party for example, either to resolve the dispute
            or as part of any mediation, arbitration or court resolution or
            similar process.
          </p>
          <p>
            Legal basis for processing: our legitimate interests (Article
            6(1)(f) of the General Data Protection Regulation).
            <span>&nbsp;</span>
            <br />
            Legitimate interest(s):resolving disputes and potential disputes.
          </p>
          <p>
            For ongoing compliance with laws, regulations and other legal
            requirements
          </p>
          <p>
            We will use and process your information in order to comply with
            legal obligations to which we are subject. For example, we may need
            to disclose your information pursuant to a court order or subpoena
            if we receive one.
          </p>
          <p>
            Legal basis for processing:compliance with a legal obligation
            (Article 6(1)(c) of the General Data Protection Regulation).
            <span>&nbsp;</span>
            <br />
            Legal obligation(s):legal obligations to disclose information which
            are part of the laws of England and Wales, or if they have been
            integrated into the United Kingdom’s legal framework (for example in
            the form of an international agreement which the United Kingdom has
            signed).
          </p>
          <p>
            Legal basis for processing:our legitimate interests(Article 6(1)(f)
            of the General Data Protection Regulation).<span>&nbsp;</span>
            <br />
            Legitimate interest: where the legal obligations are part of the
            laws of another country and have not been integrated into the United
            Kingdom’s legal framework, we have a legitimate interest in
            complying with these obligations.
          </p>
          <p>How long we retain your information</p>
          <p>
            This section sets out how long we retain your information. We have
            set out specific retention periods where possible. Where that has
            not been possible, we have set out the criteria we use to determine
            the retention period.
          </p>
          <p>Retention periods</p>
          <p>
            Correspondence and enquiries: when you make an enquiry, or
            correspond with us for any reason, whether by email, via post or by
            phone, we will retain your information for as long as it takes to
            respond to and resolve your enquiry, and for one further year, after
            which point we will delete your information.
          </p>
          <p>
            E-Newsletter: we retain the information you used to sign up for our
            e-newsletter for as long as you remain subscribed (i.e. you do not
            unsubscribe) or if we decide to cancel our e-newsletter service,
            whichever comes earlier.
          </p>
          <p>Criteria for determining retention periods</p>
          <p>
            In any other circumstances, we will retain your information for no
            longer than necessary, taking into account the following:
          </p>
          <p>
            the purpose(s) and use of your information both now and in the
            future (such as whether it is necessary to continue to store that
            information in order to continue to perform our obligations under a
            contract with you or to contact you in the future);
            <span>&nbsp;</span>
            <br />
            whether we have any legal obligation to continue to process your
            information (such as any record-keeping obligations imposed by
            relevant law or regulation);<span>&nbsp;</span>
            <br />
            whether we have any legal basis to continue to process your
            information (such as your consent);<span>&nbsp;</span>
            <br />
            how valuable your information is (both now and in the future);
            <span>&nbsp;</span>
            <br />
            any relevant agreed industry practices on how long information
            should be retained;<span>&nbsp;</span>
            <br />
            the levels of risk, cost and liability involved with us continuing
            to hold the information;<span>&nbsp;</span>
            <br />
            how hard it is to ensure that the information can be kept up to date
            and accurate; and<span>&nbsp;</span>
            <br />
            any relevant surrounding circumstances (such as the nature and
            status of our relationship with you).<span>&nbsp;</span>
            <br />
            How we secure your information
          </p>
          <p>
            We take appropriate technical and organisational measures to secure
            your information and to protect it against unauthorised or unlawful
            use and accidental loss or destruction, including:
          </p>
          <p>
            only sharing and providing access to your information to the minimum
            extent necessary, subject to confidentiality restrictions where
            appropriate, and on an anonymised basis wherever possible;
            <span>&nbsp;</span>
            <br />
            using secure servers to store your information;<span>&nbsp;</span>
            <br />
            verifying the identity of any individual who requests access to
            information prior to granting them access to information; and
            <span>&nbsp;</span>
            <br />
            using Secure Sockets Layer (SSL) software to encrypt any information
            you submit to us via any forms on our website.<span>&nbsp;</span>
            <br />
            Transmission of information to us by email
          </p>
          <p>
            Transmission of information over the internet is not entirely
            secure, and if you submit any information to us over the internet
            (whether by email, via our website or any other means), you do so
            entirely at your own risk.
          </p>
          <p>
            We cannot be responsible for any costs, expenses, loss of profits,
            harm to reputation, damages, liabilities or any other form of loss
            or damage suffered by you as a result of your decision to transmit
            information to us by such means.
          </p>
          <p>
            Transfers of your information outside the European Economic Area
          </p>
          <p>
            Your information will be transferred and stored outside the European
            Economic Area (EEA) in the circumstances set out below.
          </p>
          <p>
            We will also transfer your information outside the EEA or to an
            international organisation in order to comply with legal obligations
            to which we are subject (compliance with a court order, for
            example). Where we are required to do so, we will ensure appropriate
            safeguards and protections are in place.
          </p>
          <p>Contact Form</p>
          <p>
            Information you submit to us via our contact form is transferred
            outside the EEA and stored on our third party service provider. Our
            third party service provider is: MailChimp. You can access their
            privacy policy here: https://mailchimp.com/legal/privacy/
          </p>
          <p>
            Country of storage: United States of America. This country is not
            subject to an adequacy decision by the European Commission.
          </p>
          <p>
            Safeguard(s) used: MailChimp has self-certified its compliance with
            the EU-U.S. Privacy Shield which is available here:
            https://www.privacyshield.gov/welcome. The EU-U.S. Privacy Shield is
            an approved certification mechanism under Article 42 of the general
            Data Protection Regulation, which is permitted under Article
            46(2)(f) of the General Data Protection Regulation. You can access
            the European Commission decision on the adequacy of the EU-U.S.
            Privacy Shield here:
            https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/eu-us-privacy-shield_en.
          </p>
          <p>E-Newsletter</p>
          <p>
            Information you submit to us when you sign up for our e-newsletter
            is transferred outside the EEA and stored on our third party mailing
            list provider’s servers. Our third party mailing list provider is:
            MailChimp. You can access their privacy policy here:
            https://mailchimp.com/legal/privacy/
          </p>
          <p>
            Country of storage: United States of America. This country is not
            subject to an adequacy decision by the European Commission.
          </p>
          <p>
            Safeguard(s) used: MailChimp has self-certified its compliance with
            the EU-U.S. Privacy Shield which is available here:
            https://www.privacyshield.gov/welcome. The EU-U.S. Privacy Shield is
            an approved certification mechanism under Article 42 of the general
            Data Protection Regulation, which is permitted under Article
            46(2)(f) of the General Data Protection Regulation. You can access
            the European Commission decision on the adequacy of the EU-U.S.
            Privacy Shield here:
            https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/eu-us-privacy-shield_en.
          </p>
          <p>Google</p>
          <p>
            Information collected by Google (your IP address and actions you
            take in relation to our website) is transferred outside the EEA and
            stored on Google’s servers. You can access Google’s privacy policy
            here: https://www.google.com/policies/privacy/
          </p>
          <p>
            Country of storage: United States of America. This country is not
            subject to an adequacy decision by the European Commission.
          </p>
          <p>
            Safeguard(s) used: Google has self-certified its compliance with the
            EU-U.S. Privacy Shield which is available here:
            https://www.privacyshield.gov/welcome. The EU-U.S. Privacy Shield is
            an approved certification mechanism under Article 42 of the General
            Data Protection Regulation, which is permitted under Article
            46(2)(f) of the General Data Protection Regulation. You can access
            the European Commission decision on the adequacy of the EU-U.S.
            Privacy Shield here:
            http://ec.europa.eu/justice/data-protection/international-transfers/adequacy/index_en.htm
          </p>
          <p>Crazy Egg</p>
          <p>
            Information collected by Crazy Egg (information relating to your
            mouse behaviour on our website) is transferred outside the EEA and
            stored on Crazy Egg’s servers. You can access Crazy Egg’s privacy
            policy here: https://www.crazyegg.com/privacy
          </p>
          <p>
            Country of storage: United States of America. This country is not
            subject to an adequacy decision by the European Commission.
          </p>
          <p>
            Safeguard(s) used: Crazy Egg will put in place appropriate mechanism
            to transfer your information outside the European Economic Area.
          </p>
          <p>Facebook</p>
          <p>
            Facebook transfers information it collects from its advertisements
            and the Facebook Pixel on our website outside the EEA.
          </p>
          <p>
            Country of storage: United States of America. This country is not
            subject to an adequacy decision by the European Commission.
          </p>
          <p>
            Safeguard(s) used: where Facebook transfers your information outside
            the EEA they will ensure EU-approved model contract clauses are in
            place, obtain your consent for such transfers or adopt other means
            under EU law. EU-approved model clauses are standard data protection
            clauses adopted by the European Commission in accordance with its
            committee examination procedure under Article 93(2) of the General
            Data Protection Regulation. This is permitted under Article 46(2)(b)
            of the General Data Protection Regulation. Transfers on the basis of
            your explicit consent are permitted under Article 49(1)(a) of the
            General Data Protection Regulation.
          </p>
          <p>Scorecard Research</p>
          <p>
            Information collected by Scorecard Research is transferred outside
            the EEA and stored on Scorecard Research’s servers. You can access
            Scorecard Research’s privacy policy here:
            https://www.comscore.com/About-comScore/Privacy-Policy
          </p>
          <p>
            Country of storage: United States of America. This country is not
            subject to an adequacy decision by the European Commission.
          </p>
          <p>
            Safeguard(s) used: ScorecardResearch (comScore, Inc.) has
            self-certified its compliance with the EU-U.S. Privacy Shield which
            is available here: https://www.privacyshield.gov/welcome. The
            EU-U.S. Privacy Shield is an approved certification mechanism under
            Article 42 of the General Data Protection Regulation, which is
            permitted under Article 46(2)(f) of the General Data Protection
            Regulation. You can access the European Commission decision on the
            adequacy of the EU-U.S. Privacy Shield here:
            http://ec.europa.eu/justice/data-protection/international-transfers/adequacy/index_en.htm
          </p>
          <p>Rubicon Project</p>
          <p>
            Rubicon Project transfers information it collects using cookies
            outside the EEA.
          </p>
          <p>
            Country of storage: United States of America. This country is not
            subject to an adequacy decision by the European Commission.
          </p>
          <p>
            Safeguard(s) used: where Rubicon Project transfers your information
            outside the EEA they will ensure EU-approved model contract clauses
            are in place. EU-approved model clauses are standard data protection
            clauses adopted by the European Commission in accordance with its
            committee examination procedure under Article 93(2) of the General
            Data Protection Regulation. This is permitted under Article 46(2)(b)
            of the General Data Protection Regulation.
          </p>
          <p>Your rights in relation to your information</p>
          <p>
            Subject to certain limitations on certain rights, you have the
            following rights in relation to your information, which you can
            exercise by writing to E-cloth Ltd, East Barn, Furnace Farm, Furnace
            Lane, Lamberhurst, Kent TN3 8LE or sending an email to
            connect@uke-cloth.xyz.
          </p>
          <p>
            to request access to your informationand information related to our
            use and processing of your information;<span>&nbsp;</span>
            <br />
            to request the correction or deletionof your information;
            <span>&nbsp;</span>
            <br />
            to request that we restrict our useof your information;
            <span>&nbsp;</span>
            <br />
            to receive information which you have provided to us in a
            structured, commonly used and machine-readable format(e.g. a CSV
            file) and the right to have that information transferred to another
            data controller (including a third party data controller);
            <span>&nbsp;</span>
            <br />
            to object to the processing of your information for certain
            purposes(for further information, see the section below entitled
            Your right to object to the processing of your information for
            certain purposes); and<span>&nbsp;</span>
            <br />
            to withdraw your consent to our use of your information at any time
            where we rely on your consent to use or process that information.
            Please note that if you withdraw your consent, this will not affect
            the lawfulness of our use and processing of your information on the
            basis of your consent before the point in time when you withdraw
            your consent.<span>&nbsp;</span>
            <br />
            In accordance with Article 77 of the General Data Protection
            Regulation, you also have the right to lodge a complaint with a
            supervisory authority, in particular in the Member State of your
            habitual residence, place of work or of an alleged infringement of
            the General Data Protection Regulation.
          </p>
          <p>
            For the purposes of the UK, the supervisory authority is the
            Information Commissioner’s Office (ICO), the contact details of
            which are available here: https://ico.org.uk/global/contact-us/
          </p>
          <p>
            Further information on your rights in relation to your personal data
            as an individual
          </p>
          <p>
            The above rights are provided in summary form only and certain
            limitations apply to many of these rights. For further information
            about your rights in relation to your information, including any
            limitations which apply, please visit the following pages on the
            ICO’s website:
          </p>
          <p>
            https://ico.org.uk/for-organisations/guide-to-the-general-data-protection-regulation-gdpr/individual-rights/;
            and<span>&nbsp;</span>
            <br />
            https://ico.org.uk/for-the-public/is-my-information-being-handled-correctly/
            <span>&nbsp;</span>
            <br />
            You can also find out further information about your rights, as well
            as information on any limitations which apply to those rights, by
            reading the underlying legislation contained in Articles 12 to 22
            and 34 of the General Data Protection Regulation, which is available
            here:http://ec.europa.eu/justice/data-protection/reform/files/regulation_oj_en.pdf
          </p>
          <p>
            Verifying your identity where you request access to your information
          </p>
          <p>
            Where you request access to your information, we are required by law
            to use all reasonable measures to verify your identity before doing
            so.
          </p>
          <p>
            These measures are designed to protect your information and to
            reduce the risk of identity fraud, identity theft or general
            unauthorised access to your information.
          </p>
          <p>How we verify your identity</p>
          <p>
            Where we possess appropriate information about you on file, we will
            attempt to verify your identity using that information.
          </p>
          <p>
            If it is not possible to identity you from such information, or if
            we have insufficient information about you, we may require original
            or certified copies of certain documentation in order to be able to
            verify your identity before we are able to provide you with access
            to your information.
          </p>
          <p>
            We will be able to confirm the precise information we require to
            verify your identity in your specific circumstances if and when you
            make such a request.
          </p>
          <p>
            Your right to object to the processing of your information for
            certain purposes
          </p>
          <p>
            You have the following rights in relation to your information, which
            you may exercise in the same way as you may exercise by writing to
            E-cloth Ltd, East Barn, Furnace Farm, Furnace Lane, Lamberhurst,
            Kent TN3 8LE or sending an email to connect@uke-cloth.xyz.
          </p>
          <p>
            to object to us using or processing your information where we use or
            process it in order to carry out a task in the public interestor for
            our legitimate interests, including ‘profiling’ (i.e. analysing or
            predicting your behaviour based on your information) based on any of
            these purposes; and<span>&nbsp;</span>
            <br />
            to object to us using or processing your information for direct
            marketing purposes(including any profiling we engage in that is
            related to such direct marketing).<span>&nbsp;</span>
            <br />
            You may also exercise your right to object to us using or processing
            your information for direct marketing purposes by:
          </p>
          <p>
            clicking the unsubscribe linkcontained at the bottom of any
            marketing email we send to you and following the instructions which
            appear in your browser following your clicking on that link; or
            <span>&nbsp;</span>
            <br />
            sending an email to connect@uke-cloth.xyz, asking that we stop
            sending you marketing communications or by including the words “OPT
            OUT”.
            <span>&nbsp;</span>
            <br />
            For more information on how to object to our use of information
            collected from cookies and similar technologies, please see the
            section entitled How to accept or reject cookiesin our cookies
            policy, which is available here:
            https://www.e-cloth.com/pages/cookie-policy
          </p>
          <p>Sensitive Personal Information</p>
          <p>
            ‘Sensitive personal information’ is information about an individual
            that reveals their racial or ethnic origin, political opinions,
            religious or philosophical beliefs, or trade union membership,
            genetic information, biometric information for the purpose of
            uniquely identifying an individual, information concerning health or
            information concerning a natural person’s sex life or sexual
            orientation.
          </p>
          <p>
            We do not knowingly or intentionally collect sensitive personal
            information from individuals, and you must not submit sensitive
            personal information to us.
          </p>
          <p>
            If, however, you inadvertently or intentionally transmit sensitive
            personal information to us, you will be considered to have
            explicitly consented to us processing that sensitive personal
            information under Article 9(2)(a) of the General Data Protection
            Regulation. We will use and process your sensitive personal
            information for the purposes of deleting it.
          </p>
          <p>Changes to our Privacy Policy</p>
          <p>We update and amend our Privacy Policy from time to time.</p>
          <p>Minor changes to our Privacy Policy</p>
          <p>
            Where we make minor changes to our Privacy Policy, we will update
            our Privacy Policy with a new effective date stated at the beginning
            of it. Our processing of your information will be governed by the
            practices set out in that new version of the Privacy Policy from its
            effective date onwards.
          </p>
          <p>
            Major changes to our Privacy Policy or the purposes for which we
            process your information
          </p>
          <p>
            Where we make major changes to our Privacy Policy or intend to use
            your information for a new purpose or a different purpose than the
            purposes for which we originally collected it, we will notify you by
            email (where possible) or by posting a notice on our website.
          </p>
          <p>
            We will provide you with the information about the change in
            question and the purpose and any other relevant information before
            we use your information for that new purpose.
          </p>
          <p>
            Wherever required, we will obtain your prior consent before using
            your information for a purpose that is different from the purposes
            for which we originally collected it.
          </p>
          <p>Children’s Privacy</p>
          <p>
            Because we care about the safety and privacy of children online, we
            comply with the Children’s Online Privacy Protection Act of 1998
            (COPPA). COPPA and its accompanying regulations protect the privacy
            of children using the internet. We do not knowingly contact or
            collect information from persons under the age of 18. The website is
            not intended to solicit information of any kind from persons under
            the age of 18.
          </p>
          <p>
            It is possible that we could receive information pertaining to
            persons under the age of 18 by the fraud or deception of a third
            party. If we are notified of this, as soon as we verify the
            information, we will, where required by law to do so, immediately
            obtain the appropriate parental consent to use that information or,
            if we are unable to obtain such parental consent, we will delete the
            information from our servers. If you would like to notify us of our
            receipt of information about persons under the age of 18, please do
            so by sending an email to connect@uke-cloth.xyz.
          </p>
          <p>California Do Not Track Disclosures</p>
          <p>
            “Do Not Track” is a privacy preference that users can set in their
            web browsers. When a user turns on a Do Not Track signal in their
            browser, the browser sends a message to websites requesting that
            they do not track the user. For information about Do Not Track,
            please visit www.allaboutdnt.org
          </p>
          <p>
            At this time, we do not respond to Do Not Track browser settings or
            signals. In addition, we use other technology that is standard to
            the internet, such as pixel tags, web beacons, and other similar
            technologies, to track visitors to the website. Those tools may be
            used by us and by third parties to collect information about you and
            your internet activity, even if you have turned on the Do Not Track
            signal. For information on how to opt out from tracking technologies
            used on our website, see our cookies policy which is available here:
            https://www.e-cloth.com/pages/cookie-policy
          </p>
          <p>Copyright</p>
          <p>
            The copyright in this Privacy Policy is either owned by, or licensed
            to, us and is protected by copyright laws around the world and
            copyright protection software. All intellectual property rights in
            this document are reserved.
          </p>
        </div>
      </div>
      <div className="crop-svg">
        <div className="cropSvg">
          <FontAwesomeIcon icon={faComments} />
          <p class="icon-bar__text ">chat</p>
        </div>
        <div className="cropSvg">
          <FontAwesomeIcon icon={faEnvelope} />
          <p class="icon-bar__text ">email</p>
        </div>
        <div className="cropSvg">
          <FontAwesomeIcon icon={faPhone} />
          <p class="icon-bar__text ">call</p>
        </div>
        <div className="cropSvg">
          <FontAwesomeIcon icon={faGear} />
          <p class="icon-bar__text ">FAQ</p>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Privacy;
