import React, { useState, useContext } from "react";
import { useParams } from "react-router-dom";
import productData from "../Datas/productData";
import Header from "./Header";
import Footer from "./Footer";
import "../css/productdetails.css";
import { useNavigate } from "react-router-dom";
import { CartContext } from "../Component/CartContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBasketShopping } from "@fortawesome/free-solid-svg-icons";

const ProductDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const product = productData.find((item) => item.id === parseInt(id));
  const [quantities, setQuantities] = useState(1);
  const { addToCart } = useContext(CartContext);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleAddToCart = () => {
    addToCart(product.id, quantities);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    navigate("/cartpage");
  };
  if (!product) {
    return <div>Product not found</div>;
  }

  const calculateSave = (price, prePrice) => {
    const priceNumber = parseFloat(price.replace("£", ""));
    const prePriceNumber = parseFloat(prePrice.replace("£", ""));
    return ((prePriceNumber - priceNumber) / prePriceNumber) * 100;
  };

  const calculateGap = (price, prePrice) => {
    const priceNumber = parseFloat(price.replace("£", ""));
    const prePriceNumber = parseFloat(prePrice.replace("£", ""));
    return prePriceNumber - priceNumber;
  };

  return (
    <div className="main-container">
      <Header />
      <div className="shopDiv">
        <div className="product-detail">
          <div className="product-images">
            <div className="carousel">
              {product.images.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`${product.name}`}
                  style={{ display: index === currentIndex ? "block" : "none" }}
                />
              ))}
            </div>
            <div className="carousel-buttons">
              {product.images.map((image, index) => (
                <button key={index} onClick={() => setCurrentIndex(index)}>
                  <img src={image} alt={`${product.name}`} />
                </button>
              ))}
            </div>
            <div className="goBackToShop">
              <a href="/">{"<"}&nbsp;&nbsp;&nbsp;Go Back To Shopping.</a>
            </div>
          </div>
          <div className="product-info">
            <div className="g-0">
              <div className="bg-quaternary">
                <h3>{product.name}</h3>
              </div>
            </div>
            <div className="div-two-price">
              <h4 className="stock-in">{product.price}</h4>
              <p>{product.preprice}</p>
            </div>
            {product.preprice && (
              <p className="save-price">
                You save:
                {calculateSave(product.price, product.preprice).toFixed(0)}
                %&nbsp;(£
                {calculateGap(product.price, product.preprice).toFixed(2)})
              </p>
            )}
            <div className="summary-container">
              <div className="template_content">
                <div className="takeQuantity">
                  <input
                    type="number"
                    value={quantities}
                    min="1"
                    onChange={(e) =>
                      setQuantities(Math.max(1, parseInt(e.target.value, 10)))
                    }
                    className="quantity-input"
                  />
                </div>
                <div className="btnDiv">
                  <button className="add-to-cart" onClick={handleAddToCart}>
                    <FontAwesomeIcon icon={faBasketShopping} />
                    Add to Cart
                  </button>
                </div>
              </div>
              <div className="easyslider-anchor">
                <div class="easyslider-header-text">DESCRIPTION</div>
                <p>
                  {product.description}
                  <br /> <br />
                  <strong>Includes:</strong>
                  {product.Includes}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ProductDetail;
